<template>
  <StepPage pageName="Verificar resposta da proposta" :pageNumber="2" />
</template>

<script>
import StepPage from '@/components/SpecialistSteps/StepPage';

export default {
  name: 'CheckProposalResponse',
  components: {
    StepPage
  }
};
</script>
